<template>
  <div class="app-container" v-loading="this.isLoading">
<!--    <el-tabs v-model="activeTab">-->
<!--      <el-tab-pane label="API" name="API">-->
<!--        -->
<!--      </el-tab-pane>-->
<!--    </el-tabs>-->
    <el-form ref="form" label-width="300px">
      <MyFormItem label="ClanId" tooltip="">
        <el-input class="form-input" v-model="showRemoveBadge.clanId" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="BadgeId" tooltip="">
        <el-input-number v-model="showRemoveBadge.badgeId" :step="1" size="small" :min="0"/>
      </MyFormItem>

      <el-table :data="showRemoveBadge.result" border v-if="showRemoveBadge.result.length > 0">
        <el-table-column
            prop="Code"
            label="Code"/>
        <el-table-column
            prop="Message"
            label="Message"/>
      </el-table>

      <el-button type="primary" @click="onRemoveBadge()">
        Remove Badge
      </el-button>

      <br/>
      <br/>

      <MyFormItem label="ClanId" tooltip="">
        <el-input class="form-input" v-model="showAddBadge.clanId" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="BadgeId" tooltip="">
        <el-input-number v-model="showAddBadge.badgeId" :step="1" size="small" :min="0"/>
      </MyFormItem>

      <el-table :data="showAddBadge.result" border v-if="showAddBadge.result.length > 0">
        <el-table-column
            prop="Code"
            label="Code"/>
        <el-table-column
            prop="Message"
            label="Message"/>
      </el-table>

      <el-button type="primary" @click="onAddBadge()">
        Add Badge
      </el-button>

      <br/>
      <br/>

      <MyFormItem label="ClanId" tooltip="">
        <el-input class="form-input" v-model="showAddClanPerkEvent.clanId" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="PassivePerkId" tooltip="">
        <el-input-number v-model="showAddClanPerkEvent.passivePerkId" :step="1" size="small" :min="0"/>
      </MyFormItem>

      <MyFormItem label="TimeStampExpire" tooltip="">
        <el-input-number v-model="showAddClanPerkEvent.timeStampExpire" :step="1" size="small" :min="0"/>
      </MyFormItem>

      <el-table :data="showAddClanPerkEvent.result" border v-if="showAddClanPerkEvent.result.length > 0">
        <el-table-column
            prop="Code"
            label="code"/>
        <el-table-column
            prop="Message"
            label="Message"/>
      </el-table>

      <el-button type="primary" @click="onAddClanPerkEvent()">
        Add Clan perk
      </el-button>

      <br/>
      <br/>

<!--      <MyFormItem label="ClanId" tooltip="">-->
<!--        <el-input class="form-input" v-model="showAddResourceDonate.clanId" size="normal" clearable/>-->
<!--      </MyFormItem>-->

<!--      <el-table :data="showAddResourceDonate.result" border v-if="showAddResourceDonate.result.length > 0">-->
<!--        <el-table-column-->
<!--            prop="Code"-->
<!--            label="code"/>-->
<!--      </el-table>-->

<!--      <el-button type="primary" @click="onAddResourceDonate()">-->
<!--        Add Resource donate-->
<!--      </el-button>-->

<!--      <br/>-->
<!--      <br/>-->

<!--      <MyFormItem label="ClanId" tooltip="">-->
<!--        <el-input class="form-input" v-model="showAddPerkDonate.clanId" size="normal" clearable/>-->
<!--      </MyFormItem>-->

<!--      <el-table :data="showAddPerkDonate.result" border v-if="showAddPerkDonate.result.length > 0">-->
<!--        <el-table-column-->
<!--            prop="Code"-->
<!--            label="code"/>-->
<!--      </el-table>-->

<!--      <el-button type="primary" @click="onAddPerkDonate()">-->
<!--        Add Perk donate-->
<!--      </el-button>-->

      <br/>
      <br/>

      <MyFormItem label="ClanId" tooltip="">
        <el-input class="form-input" v-model="showAddUser.clanId" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="UserId" tooltip="">
        <el-input class="form-input" v-model="showAddUser.userId" size="normal" clearable/>
      </MyFormItem>

      <el-table :data="showAddUser.result" border v-if="showAddUser.result.length > 0">
        <el-table-column
            prop="Code"
            label="code"/>
        <el-table-column
            prop="Message"
            label="Message"/>
      </el-table>

      <el-button type="primary" @click="onAddUser()">
        Add User
      </el-button>

      <br/>
      <br/>

      <MyFormItem label="ClanId" tooltip="">
        <el-input class="form-input" v-model="showDeleteClan.clanId" size="normal" clearable/>
      </MyFormItem>

      <el-table :data="showDeleteClan.result" border v-if="showDeleteClan.result.length > 0">
        <el-table-column
            prop="Code"
            label="code"/>
        <el-table-column
            prop="Message"
            label="Message"/>
      </el-table>

      <el-button type="primary" @click="onDeleteClan()">
        Delete Clan
      </el-button>

    </el-form>
  </div>
</template>

<script>
import { Message, MessageBox } from "element-ui";
import requestClan from "@/utils/request-service-clan";
import MyFormItem from "@/views/loop-machine/component/myFormItem.vue";

export default {
  name: "Clan API",
  components: {MyFormItem},
  data() {
    return {
      activeTab: "API",
      isLoading: false,
      showRemoveBadge: {
        clanId: "",
        badgeId: "",
        result: [],
      },
      showAddBadge: {
        clanId: "",
        badgeId: "",
        result: [],
      },
      showAddClanPerkEvent: {
        clanId: "",
        passivePerkId: 10001,
        timeStampExpire: 3600,
        result: [],
      },
      showAddResourceDonate: {
        clanId: "",
        result: [],
      },
      showAddPerkDonate: {
        clanId: "",
        result: [],
      },
      showAddUser: {
        clanId: "",
        userId: "",
        result: [],
      },
      showDeleteClan: {
        clanId: "",
        result: [],
      },
    }
  },
  async created() {
    this.loading = false;
  },

  methods: {
    async onRemoveBadge() {
      this.loading = true;

      try {
        this.showRemoveBadge.result = [];
        let responseFull = await requestClan({
          url: `/maintain/remove-badge-from-clan`,
          method: `post`,
          data: {
            clanId: this.showRemoveBadge.clanId,
            badgeId: this.showRemoveBadge.badgeId,
          },
        });

        let response = responseFull.data;
        let showData = {
          Message: response.message,
          Code: response.code,
        };

        this.showRemoveBadge.result.push(showData);
      }
      catch (ex) {
        Message({
          message: `Remove Badge error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }

      this.loading = false;
    },
    async onAddBadge() {
      this.loading = true;

      try {
        this.showAddBadge.result = [];
        let responseFull = await requestClan({
          url: `/maintain/send-badge-to-clan`,
          method: `post`,
          data: {
            clanId: this.showAddBadge.clanId,
            badgeId: this.showAddBadge.badgeId,
          },
        });

        let response = responseFull.data;
        let showData = {
          Message: response.message,
          Code: response.code,
        };

        this.showAddBadge.result.push(showData);
      }
      catch (ex) {
        Message({
          message: `Add Badge error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }

      this.loading = false;
    },
    async onAddClanPerkEvent() {
      this.loading = true;

      try {
        this.showAddClanPerkEvent.result = [];
        let responseFull = await requestClan({
          url: `/maintain/add-clan-perk-event`,
          method: `post`,
          data: {
            clanId: this.showAddClanPerkEvent.clanId,
          },
        });

        let response = responseFull.data;
        let showData = {
          Message: response.message,
          Code: response.code,
        };

        this.showAddClanPerkEvent.result.push(showData);
      }
      catch (ex) {
        Message({
          message: `Add Clan perk error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }

      this.loading = false;
    },
    async onAddResourceDonate() {
      this.loading = true;

      try {
        this.showAddResourceDonate.result = [];
        let responseFull = await requestClan({
          url: `/maintain/add-resource-donate`,
          method: `post`,
          data: {
            clanId: this.showAddResourceDonate.clanId,
          },
        });

        let response = responseFull.data;
      }
      catch (ex) {
        Message({
          message: `Add resource donate error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }

      this.loading = false;
    },
    async onAddPerkDonate() {
      this.loading = true;

      try {
        this.showAddPerkDonate.result = [];
        let responseFull = await requestClan({
          url: `/maintain/add-perk-donate`,
          method: `post`,
          data: {
            clanId: this.showAddPerkDonate.clanId,
          },
        });

        let response = responseFull.data;
      }
      catch (ex) {
        Message({
          message: `Add perk donate error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }

      this.loading = false;
    },
    async onAddUser() {
      this.loading = true;

      try {
        this.showAddUser.result = [];
        let responseFull = await requestClan({
          url: `/maintain/add-user`,
          method: `post`,
          data: {
            clanId: this.showAddUser.clanId,
            userId: this.showAddUser.userId,
          },
        });

        let response = responseFull.data;
        let showData = {
          Message: response.message,
          Code: response.code,
        };

        this.showAddUser.result.push(showData);
      }
      catch (ex) {
        Message({
          message: `Add user error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }

      this.loading = false;
    },
    async onDeleteClan() {
      this.loading = true;

      try {
        this.showDeleteClan.result = [];
        let responseFull = await requestClan({
          url: `/maintain/delete-clan`,
          method: `post`,
          data: {
            clanId: this.showDeleteClan.clanId,
          },
        });

        let response = responseFull.data;
        let showData = {
          Message: response.message,
          Code: response.code,
        };

        this.showDeleteClan.result.push(showData);
      }
      catch (ex) {
        Message({
          message: `Delete clan error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }

      this.loading = false;
    },
  },
};
</script>